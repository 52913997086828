<template>
	<div class="keg-calculator">

		<form class="form keg-calculator-form row" name="form" @submit.prevent.stop="calculate(data)">
			<div class="col-md-8 col-sm-7">
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label for="capacity">What is your SYSTEM’S CAPACITY (BBLs)?</label>
							<div class="input-group">
								<input type="number" class="form-control" id="capacity" v-model="data.capacity" step="1" min="1" required>
								<span class="input-group-text">BBLs</span>
							</div>
						</div>

						<div class="form-group">
							<label for="ferment_capacity">What is your FERMENTING CAPACITY?</label>
							<input type="number" class="form-control" id="ferment_capacity" v-model="data.fermentCapacity" step="1" min="1" required>
						</div>

						<div class="form-group">
							<label for="ales">What % of your beers are ALES compared to lagers and specialty?</label>
							<div class="input-group">
								<input type="number" class="form-control" id="ales" v-model="data.ales" step="0.5" min="0" max="100" required>
								<span class="input-group-text">%</span>
							</div>
						</div>

						<div class="form-group">
							<label for="turnover">What is your KEG TURNOVER (in weeks)?</label>
							<div class="input-group">
								<input type="number" class="form-control" id="turnover" v-model="data.turnover" step="1" min="1" max="52" required>
								<span class="input-group-text">Weeks</span>
							</div>
						</div>
					</div>

					<div class="col-md-6">

						<div class="form-group">
							<label for="kegged">What % of your beer is KEGGED?</label>
							<div class="input-group">
								<input type="number" class="form-control" id="kegged" v-model="data.kegged" step="0.5" min="0" max="100" required>
								<span class="input-group-text">%</span>
							</div>
						</div>

						<div class="form-group">
							<label for="half">What % of your kegged beer will go in 1/2 BBL KEGS?</label>
							<div class="input-group">
								<input type="number" class="form-control" id="half" v-model="data.half" step="0.5" min="0" max="100" required>
								<span class="input-group-text">%</span>
							</div>
						</div>

						<div class="form-group">
							<label for="sixth">What % of your kegged beer will go in 1/6 BBL KEGS? <small>(optional)</small></label>
							<div class="input-group">
								<input type="number" class="form-control" id="sixth" v-model="data.sixth" step="0.5" min="0" max="100">
								<span class="input-group-text">%</span>
							</div>
						</div>
					</div>
				</div>
				<button type="submit" class="btn btn-primary btn-block">Estimate</button>

				<div class="clearfix line-lg"></div>
			</div>

			<div class="col-md-4 col-sm-5">
				<div class="keg-calculator-totals-wrapper">
					<h4>RESULTS</h4>
					<div class="keg-calculator-totals">
						<div class="keg-calculator-total">
							<span class="keg-calculator-total-label">1/2 Barrel</span>
							<span class="keg-calculator-total-count">{{totals.half}}</span>
						</div>
						<div class="keg-calculator-total">
							<span class="keg-calculator-total-label">1/4 Barrel</span>
							<span class="keg-calculator-total-count">{{totals.quarter}}</span>
						</div>
						<div class="keg-calculator-total">
							<span class="keg-calculator-total-label">1/6 Barrel</span>
							<span class="keg-calculator-total-count">{{totals.sixth}}</span>
						</div>
						<div class="keg-calculator-total">
							<span class="keg-calculator-total-label">TOTAL</span>
							<span class="keg-calculator-total-count">{{totals.total}}</span>
						</div>
					</div>
					<div class="col-xs-12">
						<a @click="apply()" class="clickable btn btn-primary btn-block" v-if="totals.total>0">Apply to Cart</a>
					</div>
					<div class="clearfix"></div>
				</div>
			</div>


		</form>
	</div>
</template>

<script>
/* global calculator */
// Dependencies

// Component
export default {
	name: 'kegCalculator',
	data: () => ({
		data: {
			capacity: 1,
			fermentCapacity: 1,
			ales: 0,
			turnover: 1,
			kegged: 0,
			half: 0,
			sixth: 0,
		},
		totals: {
			half: 0,
			quarter: 0,
			sixth: 0,
			total: 0,
		},
		productIds: {
			half: '-KblNa7uZrCiXgrUenCR',
			quarter: '-K7xHhQ0G_AnoWjaueAv',
			sixth: '-K7xHx6lhCEdsQOdsBW6',
		},
	}),
	methods: {
		calculate(data) {
			const avgDays = ((data.ales / 100) * 14) + ((1 - (data.ales) / 100) * 30)
			const batches = 360 / avgDays
			const annualCapacity = batches * data.fermentCapacity
			const barrelsKegged = annualCapacity * (data.kegged / 100) * (data.turnover / 52)
			const halfSplit = Math.ceil( barrelsKegged * (data.half / 100) * 2)
			const sixthSplit = Math.ceil( barrelsKegged * (data.sixth / 100) * 6)
			const quarterSplit = Math.ceil( barrelsKegged * (1 - ((data.half / 100) + (data.sixth / 100))) * 4)
			const totalKegs = (halfSplit + quarterSplit + sixthSplit)

			this.totals = {
				half: halfSplit,
				quarter: quarterSplit,
				sixth: sixthSplit,
				total: totalKegs,
			}
		},
		apply() {
			const query = {}

			for( const size in this.totals) {
				const productId = this.productIds[size]
				if (this.totals[size] && productId) {
					query[productId] = this.totals[size]
				}
			}

			const productsUrl = calculator.WIZARD_SITE_URL + `?products=${JSON.stringify(query)}`
			window.location.href = productsUrl
		},
	},
	metaInfo: () => ({
		title: 'Keg Size Calculator',
	}),
}
</script>

<style scoped lang="scss">
@import '~@/styles/partials';

.keg-calculator {

	&-totals {
		font-family: $font-family-secondary;
		margin-bottom: 20px;

		// span {
		// 	font-size: 12px;
		// }

		// dt {
		// 	width: 120px;
		// }

		// dd {
		// 	margin-left: 140px;
		// 	margin-bottom: 10px;
		// }

		&-wrapper {
			background-color: color_('white');
			padding-bottom: 20px;
			box-shadow: $box-shadows;

			h4 {
				margin: 0;
				padding: 20px 0;
				text-align: center;
				border-bottom: 1px solid color_('gray','base');
				margin-bottom: 20px;
				background-color: #eee;
				border-bottom: 1px solid #ccc;
			}
		}
	}

	&-total {
		display: flex;
		margin-bottom: $margin/3;

		&-label {
			box-sizing: border-box;
			flex: 1 1 0px;
			padding-right: $margin/2;
			text-align: right;
		}

		&-count {
			box-sizing: border-box;
			flex: 1 1 0px;
			padding-left: $margin/2;

			&:after {
				content: ' kegs';
				font-size: 13px;
			}
		}

		&:last-of-type {
			margin-top: $margin/2;
		}
	}
}
</style>
